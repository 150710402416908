<template>

  <form @submit.prevent="hostingUpdate(hostingClone)" v-if="!hosting.parent">
    <div class="card card-content">

      <div class="card-header">
        <el-card-title :title="$t('modules.hosting.performance')" />
      </div>

      <div class="card-body pb-0" v-if="hostingClone">
        <div class="row">
          <div class="col-md-6">
            <div class="form-group mb-0">
              <label class="m-0 font-weight-bold label-required">{{ $t('modules.hosting.fields.cdn.label') }}</label>
              <i class="icon-help small px-1" v-tooltip="$t('modules.hosting.fields.cdn.desc')"></i>
              <div class="mt-1">
                <switches
                  :disabled="loading"
                  v-model="hostingClone.cdn"
                  type-bold="true"
                  theme="bootstrap"
                  color="success"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card-footer">
        <button v-if="!loading" class="btn btn-lg btn-success text-white">{{ $t('common.submit') }}</button>
        <div v-if="loading" class="bg-white p-2">
          <span class="text-gray"><loader /></span>
        </div>
      </div>

    </div>
  </form>

</template>

<script>

import { HOSTING_UPDATE, HOSTINGS_REQUEST } from '../../mutations-types'
import Switches from 'vue-switches';

import HostingTab from './_Tab'

export default {
  extends: HostingTab,
  name: 'Performance',
  components: {
    Switches
  },
  data () {
    return {
    }
  },
  mounted () {
  },
  computed: {
  },
  methods: {
  },
}
</script>
