<template>

  <auth-content v-if="hosting">

    <el-header :title="title" :titleSuffix="titleSuffix" :link="hosting.domain ? hosting.domain : null" slot="header">
      <div slot="left">
        <el-header-back :back="returnToHostingPage" />
      </div>
      <hosting-status v-if="hosting && !hosting.parent && hosting.status" :status="hosting.status" slot="actions" />
      <el-tabs>
        <el-tab v-for="tab in tabs" :key="tab.key">
          <span
            class="nav-link cursor-pointer"
            :class="{'active': tab.key == activeTab}"
            data-toggle="tab"
            @click="activeTab = tab.key"
          >
            {{ $t('modules.hosting.' + tab.key) }}
          </span>
        </el-tab>
      </el-tabs>
    </el-header>

    <revisions
      v-if="revisions && revisions.length > 0"
      :revisions="revisions"
      :loading="loadingRevisions"
      :getRevisions="hostingGetRevisions"
      :viewSeeMoreButton="revisionViewSeeMoreButton"
    />

    <component
      v-for="tab in tabs"
      :key="tab.key"
      :is="`module-hosting-tab-${tab.key}`"
      v-if="activeTab == tab.key"
      :hosting="hostingClone"
      :hostingUpdate="hostingUpdate"
      :loading="loadingTab"
    />

  </auth-content>

</template>

<script>

import {
  HOSTING_GET,
  HOSTING_UPDATE,
  HOSTINGS_REQUEST,
  HOSTING_REVISIONS,
} from '../mutations-types'
import _ from 'lodash'
import store from '@/store'
import tabs from './tabs'
import revisions from '../components/revisions'

export default {
  name: 'HostingPage',
  components: {
    ...tabs,
    'revisions': revisions,
  },
  data () {
    return {
      revisions: [],
      activeTab: 'overview',
      loadingTab: false,
      loading: true,
      loadingRevisions: false,
      hosting: null,
      hostingClone: null,
      revisionViewSeeMoreButton: true,
    }
  },
  mounted () {
    this.hostingGetRevisions()
  },
  computed: {
    world () {
      return this.$store.getters['auth/getWorld']
    },
    isWorldOwner () {
      return this.$store.getters['auth/isWorldOwner']
    },
    title () {
      if (!this.hosting) {
        return ''
      } else if (!this.hosting.parent) {
        return this.hosting.name
      } else {
        return this.hostings.find((el) => {
          return el.id == this.hosting.parent
        }).name
      }
    },
    titleSuffix () {
      if (!this.hosting || !this.hosting.parent) {
        return ''
      } else {
        return `${this.hosting.name}`
      }
    },
    worldHasCard () {
      return this.$store.getters['auth/worldHasCard']
    },
    tabs () {
      let links = []
      links.push({ key: 'overview', icon: 'home' })
      // links.push({ key: 'backups', icon: 'server' })
      links.push({ key: 'performance', icon: 'stats-growth2' })
      links.push({ key: 'seo', icon: 'file-text2' })
      // links.push({ key: 'access', icon: 'key' })
      links.push({ key: 'advanced', icon: 'wrench' })
      // links.push({ key: 'spaces', icon: 'cog' })

      // if (this.isWorldOwner) {
      //   links.push({ key: 'users', icon: 'users' })
      // }
      return links
    },
  },
  methods: {
    hostingGet () {
      return this.$store.dispatch('hosting/' + HOSTING_GET, this.$route.params.world)
    },
    hostingGetRevisions (page = 1) {
      this.loadingRevisions = true
      this.$store.dispatch('hosting/' + HOSTING_REVISIONS, {
        id: this.$route.params.hosting,
        world_id: this.$route.params.world,
        page: page,
      }).then(data => {
        if (data.length < 5) {
          this.revisionViewSeeMoreButton = false
        }
        if (page == 1) {
          this.revisions = data
        } else {
          this.revisions = this.revisions.concat(data)
        }
        this.loadingRevisions = false
      })
      .catch(error => {
        this.loadingRevisions = false
      })
    },
    hostingUpdate (hostingData) {
      this.loadingTab = true
      hostingData.world_id = this.world.id
      this.$store.dispatch('hosting/' + HOSTING_UPDATE, hostingData).then(data => {
        this.hosting = data
        this.hostingClone = data
        this.loadingTab = false
        this.notifSuccess(this.$t('modules.hosting.messages.edit_success'))
        this.hostingGetRevisions()
      })
      .catch(error => {
        this.loadingTab = false
        this.notifError(error)
      })
    },
    returnToHostingPage () {
      this.$router.push({name: 'hosting.index', params: {world: this.$route.params.world}})
    },
  },
  beforeRouteEnter (to, from, next) {
    store.dispatch('hosting/' + HOSTING_GET, {
      id: to.params.hosting,
      world_id: to.params.world,
    }).then(data => {
      next(vm => {
        vm.hosting = data
        vm.hostingClone = data
        vm.loading = false
      })
    })
    .catch(error => {
    })
  },
}
</script>

<style scoped>

</style>
