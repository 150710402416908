import _ from 'lodash'
import store from '@/store'

export default {
  props: {
    hosting: {
      type: Object,
      required: true,
    },
    hostingUpdate: {
      type: Function,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  data () {
    return {
      hostingClone: null,
    }
  },
  beforeMount () {
    this.hostingClone = _.cloneDeep(this.hosting)
  },
  computed: {
    world () {
      return this.$store.getters['auth/getWorld']
    },
  },
  methods: {
  },
}
