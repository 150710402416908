<template>

  <div class="card card-content">

    <div class="card-header">
      <el-card-title :title="$t('modules.hosting.access')" />
    </div>

    <div class="card-body" v-if="hostingClone">

      <div class="row my-1">
        <div class="pr-sm-3 my-2 my-sm-0 col-sm-4 border-sm-right">
          <div class="mb-2 font-weight-bold">{{ $t('modules.hosting.access_page.ssh') }}</div>
          <a class="btn btn-light">
            {{ $t('modules.hosting.access_page.button') }}
            <i class="icon-arrow-right7"></i>
          </a>
        </div>
        <div class="px-sm-3 my-2 my-sm-0 col-sm-4 border-sm-right">
          <div class="mb-2 font-weight-bold">{{ $t('modules.hosting.access_page.ftp') }}</div>
          <router-link tag="a" :to="{ name: 'hosting.access.ftp', params: { id: $route.params.world, hosting: $route.params.hosting } }" class="btn btn-light">
            {{ $t('modules.hosting.access_page.button') }}
            <i class="icon-arrow-right7"></i>
          </router-link>
        </div>
        <div class="pl-sm-3 my-2 my-sm-0 col-sm-4">
          <div class="mb-2 font-weight-bold">{{ $t('modules.hosting.access_page.bdd') }}</div>
          <a class="btn btn-light">
            {{ $t('modules.hosting.access_page.button') }}
            <i class="icon-arrow-right7"></i>
          </a>
        </div>
      </div>

    </div>

  </div>

</template>

<script>

import HostingTab from './_Tab'

export default {
  extends: HostingTab,
  name: 'access',
  data () {
    return {
    }
  },
  mounted () {
  },
  computed: {
  },
  methods: {
  },
}
</script>
