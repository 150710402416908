<template>

  <form @submit.prevent="hostingUpdate(hostingClone)" v-if="!hosting.parent">
    <div class="card card-content">

      <div class="card-header">
        <el-card-title :title="$t('modules.hosting.seo')" />
      </div>

      <div class="card-body pb-0" v-if="hostingClone">

        <div class="form-group mb-0">
          <label class="m-0 font-weight-bold label-required">{{ $t('modules.hosting.fields.seo_lock.label') }}</label>
          <i class="icon-help small px-1" v-tooltip="$t('modules.hosting.fields.seo_lock.desc')"></i>
          <div class="mt-1">
            <switches
              :disabled="loading"
              v-model="hostingClone.seo_lock"
              type-bold="true"
              theme="bootstrap"
              color="success"
              emit-on-mount="false"
            ></switches>
          </div>
        </div>
        <div class="form-group" v-if="!hostingClone.seo_lock">
          <label class="mb-1 font-weight-bold label-required">{{ $t('modules.hosting.fields.robots.label') }}</label>
          <i class="icon-help small px-1" v-tooltip="$t('modules.hosting.fields.robots.desc')"></i>
          <textarea class="form-control" id="" cols="30" rows="10">@todo: fetch Robots.txt data from API</textarea>
        </div>

      </div>

      <div class="card-footer">
        <button v-if="!loading" class="btn btn-lg btn-success text-white">{{ $t('common.submit') }}</button>
        <div v-if="loading" class="bg-white p-2">
          <span class="text-gray"><loader /></span>
        </div>
      </div>

    </div>

  </form>

</template>

<script>

import Switches from 'vue-switches';

import HostingTab from './_Tab'

export default {
  extends: HostingTab,
  name: 'Seo',
  components: {
      Switches
  },
  data () {
    return {
    }
  },
  mounted () {
  },
  computed: {
  },
  methods: {
  },
}
</script>
