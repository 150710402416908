<template>

  <form @submit.prevent="hostingUpdate(hostingClone)">
    <div class="card card-content">

      <div class="card-header">
        <el-card-title :title="$t('modules.hosting.advanced')" />
      </div>

      <div class="card-body pb-0">

        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label class="mb-1 font-weight-bold label-required">{{ $t('modules.hosting.fields.ssl.label') }}</label>
              <i class="icon-help small px-1" v-tooltip="$t('modules.hosting.fields.ssl.desc')"></i>
              <el-select :options="sslOptions" v-model="hostingClone.ssl" :placeholder="$t('modules.hosting.fields.ssl.label')" />
            </div>
          </div>
        </div>

      </div>

      <div class="card-footer">
        <button v-if="!loading" class="btn btn-lg btn-success text-white">{{ $t('common.submit') }}</button>
        <div v-if="loading" class="bg-white p-2">
          <span class="text-gray"><loader /></span>
        </div>
      </div>

    </div>
  </form>

</template>

<script>

import HostingTab from './_Tab'

export default {
  extends: HostingTab,
  name: 'Advanced',
  data () {
    return {
      sslOptions: [
        {
          value: 'HTTPS_ONLY',
          title: this.$t('modules.hosting.fields.ssl.options.HTTPS_ONLY'),
        },
        {
          value: 'HTTP_ONLY',
          title: this.$t('modules.hosting.fields.ssl.options.HTTP_ONLY'),
        },
        {
          value: 'BOTH',
          title: this.$t('modules.hosting.fields.ssl.options.BOTH'),
        }
      ]
    }
  },
  computed: {
  },
  methods: {
  },
}
</script>
