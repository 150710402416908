<template>

  <div>

    <div>

      <div class="card" v-if="!hosting.parent && hosting.status === 'ONLINE'">
        <div class="card-header header-elements-inline">
          <h5>
            <strong>{{ sessions_total }}</strong> {{ $t('modules.hosting.sessions') }}
          </h5>
        </div>
        <div class="card-body">
          <line-chart v-if="sessions_chart" class="sessions-chart" :chart-data="sessions_chart" :options="sessions_chart_options" :height="sessions_height"></line-chart>
        </div>
      </div>

      <div class="card" v-if="hosting && !hosting.parent">
        <div class="card-header header-elements-inline">
          <el-card-title :title="$tc('modules.hosting.test_hosting', 2)" />
          <i class="icon-help small px-1" v-tooltip="$t('modules.hosting.test_hosting_message')" v-if="hosting.spaces.length > 0"></i>
        </div>
        <div class="card-body">
          <div class="mb-3" v-if="hosting.spaces.length > 0">
            <div v-for="(space, index) in hosting.spaces" class="border-bottom">
              <div class="row py-3">
                <div class="col-md pt-1 mt-1">
                  {{ space.name }}
                </div>
                <div class="col-md text-md-right">
                  <router-link tag="button" :to="{ name: 'hosting.space', params: { id: $route.params.world, hosting: $route.params.hosting, space: space.id } }" type="button" class="btn btn-light btn-sm">
                    {{ $t('common.details') }}
                    <i class="icon-arrow-right7"></i>
                  </router-link>
                </div>
              </div>
            </div>
          </div>

          <el-alert type="info" v-if="hosting.spaces.length == 0">{{ $t('modules.hosting.test_hosting_message') }}</el-alert>
          <form @submit.prevent="submitCloneForm">

            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label class="mb-1 font-weight-bold label-required">{{ $t('modules.hosting.test_hosting_form.name.label') }}</label>
                  <i class="icon-help small px-1" v-tooltip="$t('modules.hosting.test_hosting_form.name.desc')"></i>
                  <input required v-model="hosting_test.name" class="form-control" type="text" :placeholder="$t('modules.hosting.test_hosting_form.name.label')"/>
                </div>
                <div class="form-group">
                  <label class="mb-1 font-weight-bold label-required">{{ $t('modules.hosting.test_hosting_form.clone.label') }}</label>
                  <i class="icon-help small px-1" v-tooltip="$t('modules.hosting.test_hosting_form.clone.desc')"></i>
                  <el-select :options="cloneOptions" v-model="hosting_test.clone_from" :placeholder="$t('modules.hosting.test_hosting_form.clone.label')"></el-select>
                </div>
              </div>
            </div>
            <div class="">
              <button v-if="!loading" class="btn btn-lg btn-success text-white">{{ $t('modules.hosting.test_hosting_form.submit') }}</button>
              <div v-if="loading" class="bg-white p-2">
                <span class="text-gray"><loader /></span>
              </div>
            </div>

          </form>

        </div>
      </div>
    </div>

    <canvas id="chart" class="d-none"></canvas>
  </div>
</template>

<script>

import moment from 'moment'

import HostingTab from './_Tab'

export default {
  extends: HostingTab,
  name: 'Spaces',
  data () {
    return {
      hosting_test: {
        name: '',
        clone_from: this.$route.params.hosting,
      }
    }
  },
  computed: {
    cloneOptions () {
      let options = [
        {
          value: this.hosting.id,
          title: this.hosting.name,
          detail: this.$t('modules.hosting.main_hosting')
        }
      ]
      this.hosting.spaces.map((elem, index) => {
        options.push({
          value: elem.id,
          title: elem.name,
          detail: this.$tc('modules.hosting.test_hosting', 1)
        })
      })
      return options
    },
  },
  methods: {
  },
}
</script>

<style lang="scss" scoped>
</style>
