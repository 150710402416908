<template>

    <div class="card card-content">
      <div class="card-header">
        <el-card-title :title="$t('modules.hosting.revisions.title')" />
      </div>
      <div class="card-body">
        <div v-for="revision in revisions">
          <div v-for="(change, changeKey) in revision.changes" class="d-flex align-items-start mb-1">
            <div class="d-flex align-items-center" style="min-width: 130px;">
              <span class="mr-2">
                <span v-if="revision.reviewed == null" v-tooltip="$t('modules.hosting.revisions.status.pending')">
                  <i class="icon-hour-glass text-grey"/>
                </span>
                <span v-else-if="revision.reviewed == false" v-tooltip="$t('modules.hosting.revisions.status.cancelled')">
                  <i class="icon-cancel-circle2 text-danger"/>
                </span>
                <span v-else-if="revision.reviewed == true" v-tooltip="$t('modules.hosting.revisions.status.reviewed')">
                  <i class="icon-checkmark-circle text-success"/>
                </span>
              </span>
              <span class="small text-grey mr-2">
                {{ dateAgo(revision.created_at) }}
              </span>
            </div>
            <div>
              <span>
                <span class="font-weight-semibold">{{ $t(`modules.hosting.fields.${changeKey}.label`) }}</span>
                <i class="icon-arrow-right7 text-grey small mx-1" />
                {{ $te(`modules.hosting.fields.${changeKey}.options.${change}`) ?
                     $t(`modules.hosting.fields.${changeKey}.options.${change}`) :
                       change == false ?
                         $t(`modules.hosting.option_false`) :
                         change == true ?
                           $t(`modules.hosting.option_true`) :
                           change
                }}
              </span>
              <div v-if="revision.description && revision.description != ''" class="small">{{ revision.description }}</div>
            </div>
          </div>
        </div>
        <div
          @click="revisionsPage++; getRevisions(revisionsPage)"
          v-if="viewSeeMoreButton"
          class="btn btn-sm btn-secondary py-0 px-2 mt-1"
        >{{ $t('common.see_more') }}</div>
      </div>
    </div>

</template>

<script>
import moment from 'moment'

export default {
  name: 'Revisions',
  props: {
    revisions: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
    },
    viewSeeMoreButton: {
      type: Boolean,
      default: true,
    },
    getRevisions: {
      type: Function,
      required: true,
    },
  },
  data () {
    return {
      revisionsPage: 1,
    }
  },
  computed: {
  },
  methods: {
    dateAgo (dateInput) {
      return moment(dateInput).fromNow()
    },
  },
  mounted () {
  },
}
</script>

<style scoped>
</style>
