let tabs = {
    'module-hosting-tab-overview': require('./Overview.vue').default,
    'module-hosting-tab-backups': require('./Backups.vue').default,
    'module-hosting-tab-performance': require('./Performance.vue').default,
    'module-hosting-tab-seo': require('./Seo.vue').default,
    'module-hosting-tab-access': require('./Access.vue').default,
    'module-hosting-tab-advanced': require('./Advanced.vue').default,
    'module-hosting-tab-spaces': require('./Spaces.vue').default,
    'module-hosting-tab-users': require('./Users.vue').default,
}

export default tabs

