<template>

  <form @submit.prevent="submitForm">
    <div class="card card-content">

      <div class="card-header">
        <el-card-title :title="$t('modules.hosting.backup_create')" />
      </div>

      <div class="card-body pb-0">

        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label class="mb-1 font-weight-bold label-required">{{ $t('modules.hosting.backup_create_form.name.label') }}</label>
              <i class="icon-help small px-1" v-tooltip="$t('modules.hosting.backup_create_form.name.desc')"></i>
              <input required v-model="hosting_backup_name" class="form-control" type="text" :placeholder="$t('modules.hosting.backup_create_form.name.label')"/>
            </div>
          </div>
        </div>

      </div>

      <div class="card-footer">
        <button v-if="!loading" class="btn btn-lg btn-success text-white">{{ $t('modules.hosting.backup_create_form.submit') }}</button>
        <div v-if="loading" class="bg-white p-2">
          <span class="text-gray"><loader /></span>
        </div>
      </div>

    </div>
  </form>

</template>

<script>

import { HOSTING_BACKUP_NEW } from '../../mutations-types'

import HostingTab from './_Tab'

export default {
  extends: HostingTab,
  name: 'Backups',
  data () {
    return {
      hosting_backup_name: ''
    }
  },
  computed: {
  },
  methods: {
  }
}
</script>
