<template>

  <div>
    <div v-if="hostingClone">

      <form @submit.prevent="hostingUpdate(hostingClone)">
        <div class="card card-content">

          <div class="card-header">
            <el-card-title :title="$t('modules.hosting.configuration')" />
          </div>

          <div class="card-body pb-0" v-if="hosting">

            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label class="mb-1 font-weight-bold label-required" v-if="!hosting.parent">{{ $t('modules.hosting.fields.name.label') }}</label>
                  <label class="mb-1 font-weight-bold label-required" v-if="hosting.parent">{{ $t('modules.hosting.fields.test_name.label') }}</label>
                  <i class="icon-help small px-1" v-tooltip="$t('modules.hosting.fields.name.desc')"></i>
                  <input required v-model="hostingClone.name" class="form-control" type="text" :placeholder="$t('modules.hosting.fields.name.label')"/>
                </div>
                <div class="form-group" v-if="!hosting.parent">
                  <label class="mb-1 font-weight-bold">{{ $t('modules.hosting.fields.domain.label') }}</label>
                  <i class="icon-help small px-1" v-tooltip="$t('modules.hosting.fields.domain.desc')"></i>
                  <input v-model="hostingClone.domain" class="form-control" type="text" :placeholder="$t('modules.hosting.fields.domain.label')"/>
                </div>
                <div class="form-group" v-if="!hosting.parent">
                  <label class="mb-1 font-weight-bold label-required">{{ $t('modules.hosting.fields.status.label') }}</label>
                  <i class="icon-help small px-1" v-tooltip="$t('modules.hosting.fields.status.desc')"></i>
                  <el-select :options="statusesOptions" v-model="hostingClone.status" :placeholder="$t('modules.hosting.fields.status.label')" />
                </div>
              </div>
            </div>

          </div>

          <div class="card-footer">
            <button v-if="!loading" class="btn btn-lg btn-success text-white">{{ $t('common.submit') }}</button>
            <div v-if="loading" class="bg-white p-2">
              <span class="text-gray"><loader /></span>
            </div>
          </div>

        </div>
      </form>


    </div>

    <div class="invisible">
      <hosting-status ref="status_ONLINE" status="ONLINE" />
      <hosting-status ref="status_DRAFT" status="DRAFT" />
      <hosting-status ref="status_ARCHIVED" status="ARCHIVED" />
    </div>
  </div>
</template>

<script>

import { HOSTING_UPDATE, HOSTINGS_REQUEST } from '../../mutations-types'
import _ from 'lodash'

import HostingTab from './_Tab'

export default {
  extends: HostingTab,
  name: 'Overview',
  data () {
    return {
      sessions_days_to_render: 30,
      sessions_total: this.getRandomInt() * 30,
      sessions_chart: null,
      sessions_chart_options: {
        responsive: true,
        elements: {
          line: {
            tension: 0.4
          }
        },
        legend: {
          display: false
        },
        scales: {
            xAxes: [{
              gridLines: {
                  color: "rgba(0, 0, 0, 0)",
              },
              ticks: {
                  fontColor: '#555',
                  fontSize: '10',
                  padding: 10
              }
            }],
            yAxes: [{
              gridLines: {
                  zeroLineColor: "rgba(0, 0, 0, 0.05)",
                  drawBorder: false,
                  color: "rgba(0, 0, 0, 0.05)",
              },
              ticks: {
                  beginAtZero: true,
                  fontColor: '#555',
                  fontSize: '10',
                  padding: 10
              }
            }]
        }
      },
      sessions_height: 130
    }
  },
  computed: {
    canGoOnline () {
      if (!this.hosting.checklist || this.hosting.checklist.length == 0) {
        return false
      }
      let canGoOnline = true
      Object.keys(this.hosting.checklist).map((task) => {
        let done = this.hosting.checklist[task]
        if (!done) {
          canGoOnline = false
        }
      })
      return canGoOnline
    },
    lastDays () {
      let start = moment().subtract(1, 'month')
      let days = []

      for (var i = 0; i <= this.sessions_days_to_render; i++) {
        let yesterday = start.subtract(1, 'day').format('MMMM')
        start = start.add(1, 'day')
        if (i == 0 || start.format('MMMM') != yesterday) {
          days.push(start.format('DD MMMM'))
        } else {
          days.push(start.format('DD'))
        }
        start = start.add(1, 'day')
      }

      return days
    },
    statusesOptions () {
      let statuses = []
      // if (this.canGoOnline) {
        statuses.push({
          value: 'ONLINE',
          title: (this.$refs.status_ONLINE) ? this.$refs.status_ONLINE.$el.outerHTML : this.$t('modules.hosting.fields.status.options.ONLINE'),
        })
      // }

      statuses.push({
        value: 'DRAFT',
        title: (this.$refs.status_DRAFT) ? this.$refs.status_DRAFT.$el.outerHTML : this.$t('modules.hosting.fields.status.options.DRAFT'),
      })
      statuses.push({
        value: 'ARCHIVED',
        title: (this.$refs.status_ARCHIVED) ? this.$refs.status_ARCHIVED.$el.outerHTML : this.$t('modules.hosting.fields.status.options.ARCHIVED'),
      })

      return statuses
    }
  },
  methods: {
    getSessions () {

      this.sessions_chart = null
      this.sessions_total = this.getRandomInt() * 30

      if (!document.getElementById('chart')) {
        return
      }

      var chart = document.getElementById('chart').getContext('2d'),
          gradient = chart.createLinearGradient(0, 0, 0, 200);

      gradient.addColorStop(0, 'rgba(171, 204,010, 0.7)');
      gradient.addColorStop(0.5, 'rgba(171, 204, 100, 0.3)');
      gradient.addColorStop(1, 'rgba(171, 204, 100, 0.05)');

      this.sessions_chart = {
        labels: this.lastDays,
        datasets: [
          {
            pointBackgroundColor: 'white',
            borderColor: '#ABCC64',
            borderWidth: 2,
            backgroundColor: gradient,
            data: this.lastDays.map(() => {
              return this.getRandomInt()
            })
          }
        ]
      }
    },
    getRandomInt () {
      return Math.floor(Math.random() * (90 - 5 + 1)) + 10
    }
  },
}
</script>

<style scoped>
.select2-results__option[aria-selected=true] * {
    color: #fff !important;
}
</style>
